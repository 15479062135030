import { Pagination, Row, Table, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { SalarySlipActions } from "./Action";

const { Text } = Typography;
const columns = [
   {
      title: "MNV",
      dataIndex: "maNhanVien",
      key: "ma-nhan-vien",
      width: 70,
      fixed: "left",
   },
   {
      title: "Họ Tên",
      dataIndex: "hoTen",
      key: "ho-ten",
      width: 200,
      fixed: "left",
   },
   {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 250,
   },
   // {
   //    title: "Lương Cơ Bản",
   //    dataIndex: "luongCoBan",
   //    key: "luong-co-ban",
   //    width: 160,
   //    render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   // },
   // {
   //    title: "Phụ Cấp Gửi Xe",
   //    key: "phu-cap-gui-xe",
   //    dataIndex: "phuCapGuiXe",
   //    width: 160,
   //    render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   // },
   // {
   //    title: "Phụ Cấp Ăn",
   //    key: "phu-cap-an",
   //    dataIndex: "phuCapAn",
   //    width: 160,
   //    render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   // },
   // {
   //    title: "Số Ngày Làm",
   //    key: "so-ngay-lam",
   //    dataIndex: "soNgayLam",
   //    width: 130,
   // },
   // {
   //    title: "Số Ngày Nghỉ",
   //    key: "so-ngay-nghi",
   //    dataIndex: "soNgayNghi",
   //    width: 130,
   // },
   // {
   //    title: "Số Phép Nghỉ",
   //    key: "so-phep-nghi",
   //    dataIndex: "soPhepNghi",
   //    width: 130,
   // },
   // {
   //    title: "Tiền Bảo Hiểm",
   //    key: "bao-hiem",
   //    dataIndex: "tienBaoHiem",
   //    width: 160,
   //    render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   // },
   // {
   //    title: "Trợ Cấp Bảo Hiểm",
   //    key: "tro-cap-bao-hiem",
   //    dataIndex: "troCapBaoHiem",
   //    width: 160,
   //    render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   // },
   // {
   //    title: "Tiền Tạm Ứng",
   //    key: "tam-ung",
   //    dataIndex: "tienTamUng",
   //    width: 160,
   //    render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   // },
   // {
   //    title: "Tiền Tăng Ca",
   //    key: "tang-ca",
   //    dataIndex: "tangCa",
   //    width: 160,
   //    render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   // },
   // {
   //    title: "Tiền Phạt",
   //    key: "tien-phat",
   //    dataIndex: "phat",
   //    width: 160,
   //    render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   // },
   // {
   //    title: "Tiền Thưởng",
   //    key: "tien-thuong",
   //    dataIndex: "thuong",
   //    width: 160,
   //    render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   // },
   // {
   //    title: "Lương Tháng 13",
   //    key: "luong-thang-13",
   //    dataIndex: "luongThang13",
   //    width: 160,
   //    render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   // },
   // {
   //    title: "Thưởng Tết",
   //    key: "thuong-tet",
   //    dataIndex: "thuongTet",
   //    width: 160,
   //    render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   // },
   // {
   //    title: "Thưởng Vị Trí",
   //    key: "thuong-vi-tri",
   //    dataIndex: "thuongViTri",
   //    width: 160,
   //    render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   // },
   // {
   //    title: "Thưởng KPI",
   //    key: "thuong-kpi",
   //    dataIndex: "thuongKPI",
   //    width: 160,
   //    render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   // },
   // {
   //    title: "Thực Lĩnh",
   //    key: "thuc-linh",
   //    dataIndex: "thucLinh",
   //    width: 160,
   //    render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   // },

   {
      title: "Lương cơ bản TB",
      key: "luongCoBanTB",
      dataIndex: "luongCoBanTB",
      width: 160,
      render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   },

   {
      title: "Lương Tháng 13",
      key: "luongThang13",
      dataIndex: "luongThang13",
      width: 160,
      render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   },

   {
      title: "Thưởng Tết Dương Lịch 2025",
      key: "thuongTetDuongLich2025",
      dataIndex: "thuongTetDuongLich2025",
      width: 160,
      render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   },


   {
      title: "Set Quà Tết 2025",
      key: "setQuaTet2025",
      dataIndex: "setQuaTet2025",
      width: 160,
      render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   },
   

   {
      title: "Thưởng Tết 2025",
      key: "thuongTet",
      dataIndex: "thuongTet",
      width: 160,
      render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   },

   {
      title: "Thưởng Tết Vị Trí",
      key: "thuongViTri",
      dataIndex: "thuongViTri",
      width: 160,
      render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   },

   {
      title: "Thưởng YEP",
      key: "thuongYEP",
      dataIndex: "thuongYEP",
      width: 160,
      render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   },

   {
      title: "Tiền Tạm Ứng",
      key: "tienTamUng",
      dataIndex: "tienTamUng",
      width: 160,
      render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   },


   {
      title: "Tổng Thưởng",
      key: "tongThuong",
      dataIndex: "tongThuong",
      width: 160,
      render: (number) => `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
   },


   {
      title: "Ghi Chú",
      key: "ghi-chu",
      dataIndex: "ghiChu",
      width: 400,
   },
   {
      title: "Actions",
      key: "actions",
      render: (record) => <SalarySlipActions value={record} />,
      width: 150,
      fixed: "right",
      align: "center",
   },
];

export const SalarySlips = ({ loading, value, filter, onChangeFilter }) => {
   const [source, setSource] = useState({});

   const handlePageChange = useCallback(
      (page, limit) => {
         onChangeFilter?.({ paged: page, limit });
      },
      [onChangeFilter]
   );

   useEffect(() => {
      setSource(value ? value : {});
   }, [value]);

   const { nodes = [], total = 0 } = source || {};

   const tableWidth = columns
      .map((col) => col.width)
      .reduce((cur, acc) => (acc ? (cur += acc) : cur), 0);

   const { limit, paged } = filter;
   const indexPage = (paged - 1) * limit;

   return (
      <Table
         loading={loading}
         columns={columns}
         dataSource={nodes}
         rowKey={(record) => record.id}
         scroll={{ scrollToFirstRowOnChange: true, x: tableWidth }}
         pagination={false}
         footer={() => (
            <Row justify="space-between">
               <Pagination
                  current={paged}
                  pageSize={limit}
                  total={total}
                  onChange={handlePageChange}
                  showSizeChanger
               />
               <Text
                  strong
                  children={` ${nodes.length ? indexPage + 1 : 0} to ${
                     nodes.length === limit ? indexPage + limit : nodes.length
                  } of ${total} items`}
               />
            </Row>
         )}
      />
   );
};
